import './App.css';
import PCHeader from './component/pc_header'
import PCFooter from './component/pc_footer'
import PCBody from './component/pc_body'

function App() {
  return (
    <div className="App">
        <PCHeader/>
        <PCBody/>
        <PCFooter/>
    </div>
  );
}

export default App;
