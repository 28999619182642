import logo from "../logo.svg";

function PCHeader() {
    return (
    <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <h1>中文知识分享</h1>
        <h2>——利而不害 为而不争</h2>
    </header>
    );
}

export default PCHeader;