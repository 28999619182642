import MiitBeian from "./miit_beian";
import GonganBeian from "./gongan_beian";
import CopyingRight from "./copying_right";


function PCFooter() {
    return (
        <footer className="App-footer">
            <CopyingRight/>
            <MiitBeian/>
            <GonganBeian/>
        </footer>
    );
}

export default PCFooter;