function PCBody() {
    return (
        <body>
        <br/>
        <h2>网站建设中……</h2>
        <br/>
        <h2>敬请期待</h2>
        <br/>
        </body>
    );
}
export default PCBody;
